import React from 'react'
import styled from 'styled-components'
import { ThemeProvider, ThemeContext } from '../theme'

const SectionWrapper = styled.section`
  padding: 30px 20px;

  @media (min-width: 992px) {
    padding: 65px 30px;
  }
`

const cloneChildren = props => {
  const childrenWithProps = React.Children.map(props.children, child =>
    React.cloneElement(child, { ...props })
  )

  return <div>{childrenWithProps}</div>
}

export const Section = props => (
  <ThemeProvider>
    <ThemeContext.Consumer>
      {({ theme }) => <SectionWrapper>{props.children}</SectionWrapper>}
    </ThemeContext.Consumer>
  </ThemeProvider>
)
