import { injectGlobal } from 'styled-components'

import StyreneRegularEot from '../fonts/StyreneA-Regular.eot'
import StyreneRegularWoff2 from '../fonts/StyreneA-Regular.woff2'
import StyreneRegularWoff from '../fonts/StyreneA-Regular.woff'
import StyreneRegularTtf from '../fonts/StyreneA-Regular.ttf'
import StyreneRegularSvg from '../fonts/StyreneA-Regular.svg'

import FoundersGroteskEot from '../fonts/FoundersGrotesk-Regular.eot'
import FoundersGroteskWoff2 from '../fonts/FoundersGrotesk-Regular.woff2'
import FoundersGroteskWoff from '../fonts/FoundersGrotesk-Regular.woff'
import FoundersGroteskTtf from '../fonts/FoundersGrotesk-Regular.ttf'
import FoundersGroteskSvg from '../fonts/FoundersGrotesk-Regular.svg'

import FoundersGroteskItalicEot from '../fonts/FoundersGrotesk-RegularItalic.eot'
import FoundersGroteskItalicWoff2 from '../fonts/FoundersGrotesk-RegularItalic.woff2'
import FoundersGroteskItalicWoff from '../fonts/FoundersGrotesk-RegularItalic.woff'
import FoundersGroteskItalicTtf from '../fonts/FoundersGrotesk-RegularItalic.ttf'
import FoundersGroteskItalicSvg from '../fonts/FoundersGrotesk-RegularItalic.svg'

import FoundersGroteskMonoEot from '../fonts/FoundersGroteskMonoTest-Regular.eot'
import FoundersGroteskMonoWoff2 from '../fonts/FoundersGroteskMonoTest-Regular.woff2'
import FoundersGroteskMonoWoff from '../fonts/FoundersGroteskMonoTest-Regular.woff'
import FoundersGroteskMonoTtf from '../fonts/FoundersGroteskMonoTest-Regular.ttf'
import FoundersGroteskMonoSvg from '../fonts/FoundersGroteskMonoTest-Regular.svg'

injectGlobal`

  @font-face {
    font-family: 'Styrene A';
    src: url('${StyreneRegularEot}');
    src: url('${StyreneRegularEot}?#iefix') format('embedded-opentype'),
        url('${StyreneRegularWoff2}') format('woff2'),
        url('${StyreneRegularWoff}') format('woff'),
        url('${StyreneRegularTtf}') format('truetype'),
        url('${StyreneRegularSvg}#StyreneA-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Founders Grotesk';
    src: url('${FoundersGroteskEot}');
    src: url('${FoundersGroteskEot}?#iefix') format('embedded-opentype'),
        url('${FoundersGroteskWoff2}') format('woff2'),
        url('${FoundersGroteskWoff}') format('woff'),
        url('${FoundersGroteskTtf}') format('truetype'),
        url('${FoundersGroteskSvg}#FoundersGrotesk-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Founders Grotesk Regular';
    src: url('${FoundersGroteskItalicEot}');
    src: url('${FoundersGroteskItalicEot}?#iefix') format('embedded-opentype'),
        url('${FoundersGroteskItalicWoff2}') format('woff2'),
        url('${FoundersGroteskItalicWoff}') format('woff'),
        url('${FoundersGroteskItalicTtf}') format('truetype'),
        url('${FoundersGroteskItalicSvg}#FoundersGrotesk-RegularItalic') format('svg');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'Founders Grotesk Mono';
    src: url('${FoundersGroteskMonoEot}');
    src: url('${FoundersGroteskMonoEot}?#iefix') format('embedded-opentype'),
        url('${FoundersGroteskMonoWoff2}') format('woff2'),
        url('${FoundersGroteskMonoWoff}') format('woff'),
        url('${FoundersGroteskMonoTtf}') format('truetype'),
        url('${FoundersGroteskMonoSvg}#FoundersGroteskMonoTest-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
  }
`
