import { injectGlobal } from 'styled-components'
import { colors } from './colors'

injectGlobal`
  h1 {
    font-family: "Styrene A";
    font-size: 64px;
    font-weight: normal;
    letter-spacing: -1.2px;
    line-height: 72px;
    margin-top: 0;
   
    &.h2 {
      font-size: 48px;
      line-height: 56px;
    }
    
    &.h3 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 0.5rem;
    }
    
    &.h4 {
      font-size: 32px;
      line-height: 48px;
      letter-spacing: 0;
    }
  }
  
  h2 {
    font-family: "Styrene A";
    font-size: 48px;
    font-weight: normal;
    letter-spacing: -1.2px;
    line-height: 56px;
    margin-top: 0;
  }
  
  h3 {
    font-family: "Styrene A";
    font-size: 32px;
    font-weight: normal;
    line-height: 40px;
    margin-top: 0;
  }
  
  h4 {
    font-family: "Styrene A";
    font-size: 24px;
    font-weight: normal;
    line-height: 32px;
    margin: 0 0 8px;
  }
  
  h5 {
    font-family: "Styrene A";
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    margin-top: 0;
  }
  
  h6 {
    font-family: "Styrene A";
    font-size: 10px;
    font-weight: normal;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 0;
  }
  
  html {
    font-family: "Founders Grotesk";
    font-size: 16px;
    line-height: 24px;
    color: ${colors.primary.default};
    font-weight: normal;
  }
  
  body {
    margin: 0;
  }
  
  p.blockquote,
  blockquote {
    font-family: "Founders Grotesk Mono";
    font-size: 16px;
    line-height: 24px;
  }
  
  p {
    margin-top: 0;
    
    &.large {
      font-size: 20px;
      line-height: 32px;
    }
    
    &.small {
      font-size: 14px;
      line-height: 24px;
    }
    
    &.xsmall {
      font-size: 12px;
      line-height: 20px;
    }
  }
  
  ::selection {
    background-color: ${colors.quaternary.default};
    color: #fff;
  }
`
