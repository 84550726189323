export const colors = {
  black: '#262626',
  grey: {
    xd: '#4D4D4D',
    d: '#737373',
    default: '#999999',
    l: '#BFBFBF',
    xl: '#E6E6E6',
  },
  primary: {
    xd: '#000000',
    d: '#00080B',
    default: '#002D3E',
    l: '#005271',
    xl: '#0077A4',
  },
  secondary: {
    xd: '#000507',
    d: '#00283A',
    default: '#004C6D',
    l: '#0070A0',
    xl: '#0093D3',
  },
  tertiary: {
    xd: '#2AAFCF',
    d: '#51C1DB',
    default: '#7BD0E4',
    l: '#A5DFED',
    xl: '#D0EEF5',
  },
  quaternary: {
    xd: '#B5190F',
    d: '#E41F13',
    default: '#EF463B',
    l: '#F3736A',
    xl: '#F79F99',
  },
  quinary: {
    xd: '#2C7864',
    d: '#399E83',
    default: '#4CBEA0',
    l: '#71CCB4',
    xl: '#97D9C8',
  },
}
