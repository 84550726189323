import React from 'react'
// import { theme1, theme2 } from '../../theme/globalStyle'

export const ThemeContext = React.createContext()

export class ThemeProvider extends React.Component {
  state = {
    theme: 'dark',
  }

  handleThemeChange = e => {
    let theme = e.target.value
    this.setState({ theme: this.state.theme })
  }

  render() {
    return (
      <ThemeContext.Provider
        value={{
          ...this.state,
          handleThemeChange: this.handleThemeChange,
        }}
      >
        {this.props.children}
      </ThemeContext.Provider>
    )
  }
}
