import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import MessengerCustomerChat from 'react-messenger-customer-chat'
import { ThemeProvider } from './theme'
import { Consumer } from '../store/createContext'

import pattern from '../images/pattern.svg'

import styled from 'styled-components'

import { colors } from '../styles/colors'

import Header from './header'
import { Footer } from './sections/footer'

import '../styles/fonts'
import '../styles/typography'

const SiteBackground = styled.div`
  background-color: ${props =>
    props.dark ? colors.primary.default : 'transparent'};
  color: ${props => (props.dark ? '#fff' : colors.primary.default)};
  overflow: hidden;
  position: relative;

  &::before {
    content: ' ';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: white;
    background: url('${pattern}') center top;
    will-change: transform;
    z-index: -1;
  }
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider>
        <Consumer>
          {({ dark }) => (
            <SiteBackground dark={dark}>
              <Helmet
                title={data.site.siteMetadata.title}
                meta={[
                  { name: 'description', content: 'Sample' },
                  { name: 'keywords', content: 'sample, something' },
                  {
                    name: 'google-site-verification',
                    content: 'googlea63a66b7abed9c74',
                  },
                ]}
              >
                <html lang="en" />
                <meta
                  name="p:domain_verify"
                  content="8fa023327921bcd8755c9f658cea6549"
                />
              </Helmet>
              <Header siteTitle={data.site.siteMetadata.title} />
              <div>{children}</div>
              <Footer />
              <MessengerCustomerChat
                pageId="1185593028156836"
                themeColor="#EF463B"
                minimized={true}
                loggedInGreeting="Hey there! How can we help you?"
                loggedOutGreeting="Hey there! How can we help you?"
              />
            </SiteBackground>
          )}
        </Consumer>
      </ThemeProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
