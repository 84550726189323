import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { colors } from '../../../styles/colors'
import { Button } from '../../button'
import { Grid } from '../../grid'
import moment from 'moment'

const FooterStyle = styled.footer`
  font-family: 'Founders Grotesk Mono';
  background-color: ${colors.quaternary.default};
  color: #fff;
  padding: 40px 30px;

  p {
    margin: 0;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  @media (max-width: 991px) {
    text-align: center;
  }
`

// const Grid = styled.div`
//   display: grid;
//   grid-template-columns: 1fr;
//   grid-gap: 20px;
//   max-width: 1140px;
//   margin: auto;
//   padding: 40px 30px;
//
//   @media (max-width: 768px) {
//     text-align: center;
//   }
//
//   @media (min-width: 769px) {
//     grid-template-columns: 2fr 1fr;
//   }
// `

const SocialMedia = styled.nav`
  font-size: 12px;
  line-height: 20px;

  @media (min-width: 769px) {
    text-align: right;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    display: inline-block;

    &:not(:last-of-type) {
      margin-right: 10px;

      &:after {
        content: '/';
        display: inline-block;
        margin-left: 10px;
      }
    }
  }
`

export const Footer = ({ data }) => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        contact: markdownRemark(frontmatter: { type: { eq: "contactInfo" } }) {
          frontmatter {
            address1
            city
            state
            zipCode
          }
        }
        socialMedia: markdownRemark(
          frontmatter: { type: { eq: "socialMedia" } }
        ) {
          frontmatter {
            dribbble
            facebook
            instagram
          }
        }
      }
    `}
    render={data => (
      <FooterStyle>
        <Grid gap="20px" desktop={{ columns: '1fr auto' }} offset={1}>
          <p className="xsmall">
            © {moment().format('YYYY')} Pioneer {data.contact.frontmatter.address1}{' '}
            {data.contact.frontmatter.city}, {data.contact.frontmatter.state}{' '}
            {data.contact.frontmatter.zipCode}
          </p>
          {data.socialMedia && (
            <SocialMedia>
              <ul>
                {data.socialMedia.frontmatter.instagram && (
                  <li>
                    <a
                      href={`https://instagram.com/${data.socialMedia.frontmatter.instagram}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button small primary>
                        Instagram
                      </Button>
                    </a>
                  </li>
                )}
                {data.socialMedia.frontmatter.facebook && (
                  <li>
                    <a
                      href={`https://facebook.com/${data.socialMedia.frontmatter.facebook}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button small primary>
                        Facebook
                      </Button>
                    </a>
                  </li>
                )}
                {data.socialMedia.frontmatter.dribbble && (
                  <li>
                    <a
                      href={`https://dribbble.com/${data.socialMedia.frontmatter.dribbble}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button small primary>
                        Dribbble
                      </Button>
                    </a>
                  </li>
                )}
                <li>
                  <Button to={'/contact'} small primary>
                    Contact Us
                  </Button>
                </li>
              </ul>
            </SocialMedia>
          )}
        </Grid>
      </FooterStyle>
    )}
  />
)
