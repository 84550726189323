import React, { Component } from 'react'
import { Link } from './link'
import { Consumer } from '../store/createContext'
import Toggle from 'react-toggle'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons'

import styled from 'styled-components'

import { colors } from '../styles/colors'

import logo from '../logo/logomark.svg'
import logoWhite from '../logo/logomark_white.svg'

import { Button } from './button'
import { Grid } from './grid'

const Constrain = styled.div`
  margin: auto;
  padding: 30px 20px;

  @media (min-width: 992px) {
    padding: 40px;
  }
`

const Logo = styled.div`
  a {
    display: grid;
  }

  img {
    max-width: 150px;
    height: auto;
  }
`

const ToggleStyles = styled.div`
  display: flex;
  align-items: center;

  a {
    color: #fff;
  }

  label {
    margin-left: 5px;
    top: 3px;
    position: relative;
  }

  .react-toggle {
    touch-action: pan-x;

    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }

  .react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: ${colors.primary.default};
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
  }

  .react-toggle--checked .react-toggle-track {
    background-color: ${colors.quaternary.default};
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-track {
    background-color: ${colors.quaternary.default};
  }

  .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }

  .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
    color: #fff;
  }

  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }

  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid ${colors.primary.default};
    border-radius: 50%;
    background-color: #fafafa;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: ${colors.quaternary.default};
  }

  .react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
`

const Navigation = styled.nav`
  position: relative;
  z-index: 0;

  li {
    vertical-align: middle;
  }

  @media (max-width: 768px) {
    display: none;
    overflow: hidden;
    max-height: 0;
    height: 0px;
    opacity: 1;
    transition: opacity 0.25s linear;

    &.open {
      display: block;
      max-height: 100%;
      overflow: visible;
      height: 100%;
      opacity: 1;
      transition: opacity 0.25s linear;
    }
  }

  @media (min-width: 769px) {
    text-align: right;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin-bottom: 10px;

    @media (min-width: 769px) {
      display: inline-block;
      margin-bottom: 0;

      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }
  }
`

const MobileNavTrigger = styled.div`
  text-align: right;
  align-items: center;

  @media (min-width: 769px) {
    display: none;
  }

  button {
    appearance: none;
    background: transparent;
    border: none;

    &:focus,
    &:active {
      outline: none;
    }
  }
`

class Header extends Component {
  state = {
    mobileNav: false,
  }

  render() {
    const { props } = this
    const { siteTitle } = this.props

    return (
      <Consumer>
        {({ dark, toggleDarkMode }) => (
          <Constrain>
            <Grid columns="auto 1fr" align="center" offset={0.5}>
              <Logo>
                <Link to="/">
                  <img src={dark ? logoWhite : logo} alt={siteTitle} />
                </Link>
              </Logo>

              <MobileNavTrigger>
                <button
                  type="button"
                  aria-label="Menu"
                  aria-controls="navigation"
                  aria-expanded="true/false"
                  onClick={() =>
                    this.setState({ mobileNav: !this.state.mobileNav })
                  }
                >
                  <Button theme={props.theme}>
                    {this.state.mobileNav ? 'Close' : 'Menu'}
                  </Button>
                </button>
              </MobileNavTrigger>

              <Navigation
                className={this.state.mobileNav ? 'open' : ''}
                mobileNav={this.state.mobileNav}
              >
                <ul>
                  <li>
                    <Button to={'/work'} theme={props.theme}>
                      Work
                    </Button>
                  </li>
                  <li>
                    <Button to="/services" theme={props.theme}>
                      Services
                    </Button>
                  </li>
                  <li>
                    <Button to="/about" theme={props.theme}>
                      About
                    </Button>
                  </li>
                  <li>
                    <Button to="/contact" theme={props.theme}>
                      Contact
                    </Button>
                  </li>
                  <li>
                    <ToggleStyles>
                      <Toggle
                        defaultChecked={dark}
                        checked={dark}
                        onChange={toggleDarkMode}
                        icons={{
                          checked: (
                            <FontAwesomeIcon icon={faMoon} size={'xs'} />
                          ),
                          unchecked: (
                            <FontAwesomeIcon icon={faSun} size={'xs'} />
                          ),
                        }}
                      />
                    </ToggleStyles>
                  </li>
                </ul>
              </Navigation>
            </Grid>
          </Constrain>
        )}
      </Consumer>
    )
  }
}

export default Header
