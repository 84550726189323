import React from 'react'
import styled from 'styled-components'
import { ThemeContext } from '../theme'

const Constrain = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: auto;
`

const GridWrapper = styled.div`
  display: grid;
  margin: auto;
  grid-template-columns: ${props => (props.columns ? props.columns : '1fr')};
  grid-gap: ${props => (props.gap ? props.gap : '30px')};
  align-items: ${props => (props.align ? props.align : 'start')};

  @media (min-width: 576px) and (max-width: 991px) {
    grid-template-columns: ${props =>
      props.tablet && props.tablet.columns
        ? props.tablet.columns
        : props.columns
        ? props.columns
        : '1fr'};
    grid-gap: ${props =>
      props.tablet && props.tablet.gap
        ? props.tablet.gap
        : props.gap
        ? props.gap
        : '30px'};
  }

  @media (min-width: 992px) {
    margin: 0
      ${props => (props.offset ? (props.offset / 12) * 100 + '%' : '0px')};
    grid-template-columns: ${props =>
      props.desktop && props.desktop.columns
        ? props.desktop.columns
        : props.columns
        ? props.columns
        : '1fr'};
    grid-gap: ${props =>
      props.desktop && props.desktop.gap
        ? props.desktop.gap
        : props.gap
        ? props.gap
        : '30px'};
  }
`

const cloneChildren = props => {
  const childrenWithProps = React.Children.map(props.children, child =>
    React.cloneElement(child, { ...props })
  )

  return <div>{childrenWithProps}</div>
}

export const Grid = props => (
  <ThemeContext.Consumer>
    {({ theme }) => (
      <Constrain>
        <GridWrapper {...props}>{props.children}</GridWrapper>
      </Constrain>
    )}
  </ThemeContext.Consumer>
)
