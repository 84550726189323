import React, { Component } from 'react'
import { Link } from '../link'
import { Consumer } from '../../store/createContext'

import styled from 'styled-components'
import styledMap from 'styled-map'
import { colors } from '../../styles/colors'

const clickableColor = styledMap`
  white: #FFF;
  primary: ${colors.primary.default};
  secondary: ${colors.secondary.default};
  tertiary: ${colors.tertiary.default};
  default: ${colors.quaternary.default};
`

const Clickable = styled.span`
  display: inline-block;
  position: relative;
  z-index: 1;
  font-family: 'Founders Grotesk Mono', sans-serif;
  font-size: ${props => (props.small ? 12 : props.medium ? 14 : 16)}px;
  color: ${props => (props.small || props.dark ? '#FFF' : '#002D3E')};
  letter-spacing: 0;
  line-height: ${props => (props.small ? 20 : 24)}px;
  padding: 0 0 0 6px;
  margin-left: -6px;

  &:hover {
    cursor: pointer;

    :after {
      width: ${props => (props.width ? props.width - 10 + 'px' : '80%')};
      transition: width
        ${props => (props.width * 0.003 < 0.24 ? 0.24 : props.width * 0.003)}s
        linear;
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 2px;
    left: 0;
    height: ${props => (props.small || props.medium ? 6 : 8)}px;
    width: 0%;
    background-color: ${clickableColor};
    z-index: -1;
    transition: width
      ${props => (props.width * 0.003 < 0.24 ? 0.24 : props.width * 0.003)}s
      linear;
  }
`

const punctuation = new RegExp(/[!@#$%^&*()-=_+|;':",.<>?']/)

const calculateWidth = ({ small, medium, children }) => {
  const multiplyBy = small ? 8 : medium ? 8.75 : 10
  if (children.length && children[children.length - 1].match(punctuation)) {
    return (children.length - 1) * multiplyBy
  } else if (children.length) {
    return children.length * multiplyBy
  }

  return 10 * 10
}

export class Button extends Component {
  state = {
    buttonWidth: null,
  }

  constructor() {
    super()
    this.Button = React.createRef()
  }

  componentDidMount() {
    this.setState({
      buttonWidth: calculateWidth(this.Button.current.props),
    })
  }

  render() {
    const { props } = this

    if (props.to) {
      return (
        <Consumer>
          {({ dark }) => (
            <Link to={props.to}>
              <Clickable
                ref={this.Button}
                width={this.state.buttonWidth}
                dark={dark}
                {...props}
              >
                {props.children || 'Learn More'}
              </Clickable>
            </Link>
          )}
        </Consumer>
      )
    }

    return (
      <Consumer>
        {({ dark }) => (
          <Clickable
            ref={this.Button}
            width={this.state.buttonWidth}
            {...props}
            dark={dark}
          >
            {props.children || 'Learn More'}
          </Clickable>
        )}
      </Consumer>
    )
  }
}
// export const Button = (props) =>
//   <Link to={props.to}>
//     <Clickable
//       width={calculateWidth(props.children)}
//     >
//       {props.children || 'Learn More'}
//     </Clickable>
//   </Link>
